/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:de84488a-e125-44b2-89f0-62faa460d57a",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_LZwDzuugc",
    "aws_user_pools_web_client_id": "4am378p2cgm45fdvk1hbrtadk2",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://js7s2m7dbvaqzihdl4bnidkise.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-u7zzccuc7jgqhjttw3q3lhllmy",
    "aws_user_files_s3_bucket": "infermataimages172514-staging",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
