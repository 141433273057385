import React, { useState, useEffect } from 'react';
import './App.css';
import { API, Auth, Storage } from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import {Container, Header, Button, Input, Item, Icon, Form, Divider} from 'semantic-ui-react';
import { listNotes } from './graphql/queries';
import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from "./graphql/mutations";

function getUser() {
  let user = Auth.user.username;
  return user;
}

const initialFormState = { name: '', description: '', userName: '' }

function App() {
  const [notes, setNotes] = useState([]);
  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
      fetchNotes();
  }, []);

  async function fetchNotes() {
    let filter = {
      userName: {
        eq: getUser()  // filter userName = current user
      }
    };
    console.log('filter is:', filter);
    const apiData = await API.graphql({ query: listNotes , variables: { filter: filter}});
    const notesFromAPI = apiData.data.listNotes.items;
    await Promise.all(notesFromAPI.map(async note => {
      if (note.image) {
        const image = await Storage.get(note.image);
        note.image = image;
      }
      return note;
    }))
    setNotes(apiData.data.listNotes.items);
  }

  async function createNote() {
    formData.userName = getUser();
    if (!formData.name || !formData.description) return;
    await API.graphql({ query: createNoteMutation, variables: { input: formData } });
    if (formData.image) {
      const image = await Storage.get(formData.image);
      formData.image = image;
    }
    setNotes([...notes, formData]);
    setFormData(initialFormState);
  }

    async function deleteNote({ id }) {
      const newNotesArray = notes.filter(note => note.id !== id);
      setNotes(newNotesArray);
      await API.graphql({ query: deleteNoteMutation, variables: { input: { id } }});
    }

  async function onChange(e) {
    if (!e.target.files[0]) return
    const file = e.target.files[0];
    setFormData({ ...formData, image: file.name });
    await Storage.put(file.name, file);
    fetchNotes();
  }

  return (
    <Container>
      <Header as ="h1">
        My Notes App
        { ' ' }
        { getUser() }
      </Header>
      <Form>
        <Form.Field>
          <label>Note Name</label>
          <input onChange={e => setFormData({...formData, 'name': e.target.value})}
                 placeholder="Note name"
                 value={formData.name}
          />
        </Form.Field>
        <Form.Field>
          <label>Note Description</label>
          <Input
            onChange={e => setFormData({...formData, 'description': e.target.value})}
            placeholder="Note description"
            value={formData.description}
          />
        </Form.Field>
        <Form.Field>
          <label>Image File</label>
          <Input
            type="file"
            placeholder={"File name"}
            onChange={onChange}
          />
        </Form.Field>
        <Button type='submit' onClick={createNote}>Create Note</Button>
      </Form>
      <Divider />
      <div style={{marginBottom: 30}}>
        <Item.Group divided>
        {
          notes.map(note => (
            <Item>
            <div key={note.id || note.name}>
              <Item.Content>
              <Item.Header>{note.name}</Item.Header>
              <Item.Description>{note.description}</Item.Description>
                <Item.Extra>
                  <Button primary onClick={() => deleteNote(note)}>
                    Delete Note
                    {' '}
                    <Icon name='trash' />
                  </Button>
                  {
                    note.image && <img src={note.image} style={{width: 400}} alt="not supplied"/>
                  }
                </Item.Extra>
              </Item.Content>
            </div>
            </Item>
          ))
        }
        </Item.Group>
      </div>
      <AmplifySignOut/>
    </Container>
  );
}

export default withAuthenticator(App);
